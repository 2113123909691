  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-7-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert batiment Avignon</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expert technique indépendant en bâtiment à Avignon</h1>

<p>L’équipe RV Expertises, Experts bâtiments indépendants, propose un accompagnement personnalisé pour chacun de ses clients. Notre équipe est composée d’un expert bâtiment certifié OFIB et d’un ingénieur structure sapiteur.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert batiment Avignon" />
</div>


<p>Votre ouvrage fissure ? Des problèmes récurrents d’humidité ? un sinistre après une sécheresse ou une inondation ? Découvrez notre accompagnement technique personnalisé.</p>
<h2>Pourquoi faire intervenir un expert en bâtiment ?</h2>

<p>Le recours à un expert bâtiment est souvent nécessaire face à une pathologie du bâtiment (fissures, humidité, malfaçons). </p>

<p>L’expert bâtiment est un professionnel qui met à disposition ses compétences en techniques de la construction et pathologies de la construction. </p>

<p>Il dispose ainsi de solides compétences pour analyser et étudier tous défauts structurels, en déterminer les causes et définir les travaux de réparation adaptés.</p>

<h2>Prestations du cabinet RV Expertises</h2>

<p>Nous intervenons en expertise bâtiment dans les contextes suivants :</p>

<ul><li>avis technique général (avant-acquisition d’un bien immobilier),</li>
<li>désordres et sinistres : fissures, humidité,</li>
<li>litige avec constructeur ou assurance : malfaçons, absence de garanties, indemnisations insuffisantes,</li>
<li>sinistre consécutif à un aléa catastrophe naturelle</li></ul>

<p>Pour plus d’informations, n’hésitez pas à nous contacter, nous vous répondrons dans les 48 heures.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demande d’expertise</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-batiment-avignon/' className='active'>Expert batiment Avignon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details